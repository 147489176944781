import React from "react";
import { Row, Col, Image } from "react-bootstrap";

export default function Projects() {
  return (
    <Row className="py-5">
      <Col className="sticky-col p-3" sm={12} md={4}>
        <div className="">
          <div
            className="text-center"
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3
              className="subheader-font"
              style={{
                marginTop: "25%",
                marginBottom: "35%",
                fontWeight: "bold",
                zIndex: 10,
              }}
            >
              {" "}
              PERSONAL PROJECTS{" "}
            </h3>
            <Image
              style={{
                maxWidth: "90%",
                position: "absolute",
              }}
              fluid
              className="header-img"
              src="/HEADER-BG-BRIAN.png"
            ></Image>
          </div>
        </div>
      </Col>
      <Col xs={12} md={1} />
      <Col className="p-3 scrolling-col" xs={12} md={7}>
        <div className="mb-5 home-card">
          <Image src="/twitter.jpg" fluid />
          <h2 className="mt-2 mb-0 subheader-font no-shadow">
            Twitter Summarizer RESTful API
          </h2>
          <p className="paragraph-font job-date mt-1 mb-1">
            {" "}
            March 2020 – April 2020
          </p>
          <p className="paragraph-font job-date">
            <a
              target="_blank"
              href="https://github.com/brian-macomber/video-REST-brian-macomber"
            >
              Github Link
            </a>
          </p>
          <ul className="pl-4">
            <li>
              <p className="paragraph-font">
                Created a web application that receives a user’s twitter handle
                and uses the FFmpeg multimedia encoding library to create a
                video of the past 24 hours’ worth of tweets.
              </p>
            </li>
            <li>
              <p className="paragraph-font mb-0">
                Implemented the system as a multithreaded queue system to handle
                multiple users at once, deployed the app onto an AWS EC2
                instance, and integrated it to be a RESTful API.
              </p>
            </li>
          </ul>
        </div>
        <div className="mb-5 home-card">
          <Image src="/covid-tracker.jpg" fluid />
          <h2 className="mt-2 mb-0 subheader-font no-shadow">
            Covid-19 Tracker App
          </h2>
          <p className="paragraph-font mt-1 mb-1 job-date">
            {" "}
            April 2020 – May 2020{" "}
          </p>
          <p className="paragraph-font job-date">
            <a
              target="_blank"
              href="https://github.com/brian-macomber/codvid-app-brian-macomber"
            >
              Github Link
            </a>
          </p>
          <ul className="pl-4">
            <li>
              <p className="paragraph-font">
                Created and designed an IOS application using React-Native that
                pulls global and country-specific statistics on Covid-19 from an
                API and overlays it on a map.
              </p>
            </li>
            <li>
              <p className="paragraph-font mb-0">
                Provided a global summary to the user of the total confirmed
                cases, total recovered cases, and total deaths.
              </p>
            </li>
          </ul>
        </div>
        <div className="mb-5 home-card">
          <Image src="/girls.jpg" fluid />
          <h2 className="mt-2 mb-0 subheader-font no-shadow">
            Feminine Hygiene Dispenser
          </h2>
          <p className="paragraph-font mt-1 mb-4 job-date"> December 2018 </p>
          <ul className="pl-4">
            <li>
              <p className="paragraph-font">
                Worked as the team leader to design and construct a
                self-sustainable feminine hygiene dispenser by organizing team
                meetings, setting bi-weekly objectives and managing the budget
                of product materials.
              </p>
            </li>
            <li>
              <p className="paragraph-font mb-0">
                Developed code using Arduino to create an anti-hoarding and
                anti-theft dispenser by utilizing an RFID scanner for university
                ID to implement a daily limit per person.
              </p>
            </li>
          </ul>
        </div>
      </Col>
    </Row>
  );
}
