import React from "react";
import { Container } from "react-bootstrap";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import "./css/index.css";
import "./css/typography.css";
import Home from "./pages/Home";

function BRIAN_MACOMBER_WEBSITE() {
  const scrollFunction = function () {
    window.scrollTo(0, 0);
  };

  window.addEventListener("hashchange", scrollFunction);
  window.addEventListener("beforeunload", scrollFunction);

  return (
    <Container className="mt-3">
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
        </Switch>
      </Router>
    </Container>
  );
}

export default BRIAN_MACOMBER_WEBSITE;
