import React, { Component } from "react";
import ReactContactForm from "react-mail-form";
import { Row, Col } from "react-bootstrap";
import "../css/contact.css";

export default class Contact extends Component {
  render() {
    return (
      <div id="contact-section" className="py-5">
        <Row>
          <Col xs={12} className="">
            <div className="mb-5 home-card">
              <h1 className="subheader-font no-shadow">Email me!</h1>
              <p className="paragraph-font">
                I'm looking for a job once I graduate in Spring 2021.
              </p>

              <ReactContactForm
                to="brianmacomber@me.com"
                className="contact-form"
                titlePlaceholder="Subject..."
                contentsPlaceholder="Message..."
                buttonText="Send!"
                contentsMaxLength={1000}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
