import React from "react";
import { Row, Col, Image } from "react-bootstrap";

export default function WelcomeHeader() {
  return (
    <Row className="py-5">
      <Col xs={12} style={{ height: "70vh" }} className="flex-center-content">
        <h1 className="main-header-font">
          {" "}
          HI, IM <br />
          BRIAN{" "}
        </h1>
        <Image
          src="/brian-blue.png"
          className="header-image-size brian-head-wobble"
        />
      </Col>
      <Col xs={12} className="">
        <div className="text-left">
          <h3 className="subheader-font no-shadow">
            A <strong>computer engineer</strong> graduating from&nbsp;
            <strong>Boston University</strong>&nbsp;in the spring of 2021.
          </h3>
        </div>
        <div>
          <p className="paragraph-font">
            I’m Brian Macomber. I’m currently seeking an entry-level software
            engineer position where I can apply and hone skills that I’ve
            developed throughout college and through personal projects. I’m
            interested in fields such as social platforms, music technology, and
            esports because it aligns with my interests and I’m passionate about
            them.
          </p>
        </div>
      </Col>
    </Row>
  );
}
