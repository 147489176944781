import React from "react";
import { Row, Col, Image } from "react-bootstrap";

export default function WorkExperience() {
  return (
    <Row className="py-5">
      <Col className="sticky-col p-3" sm={12} md={4}>
        <div className="">
          <div
            className="text-center"
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3
              className="subheader-font"
              style={{
                marginTop: "25%",
                marginBottom: "35%",
                fontWeight: "bold",
                zIndex: 10,
              }}
            >
              {" "}
              WORK EXPERIENCE{" "}
            </h3>
            <Image
              style={{
                maxWidth: "90%",
                position: "absolute",
              }}
              fluid
              className="header-img"
              src="/HEADER-BG-BRIAN.png"
            ></Image>
          </div>
        </div>
      </Col>
      <Col xs={12} md={1} />
      <Col className="p-3 scrolling-col" xs={12} md={7}>
        <div className="mb-5 home-card">
          <div className="job-header">
            <h2 className="subheader-font mb-0 no-shadow">
              Teaching Assistant
            </h2>
            <p className="paragraph-font job-date mt-2 mb-0">
              Jan 2018 - Present
            </p>
          </div>
          <h4 className="paragraph-font mb-5">
            Boston University Dept. of Mechanical Engineering
          </h4>
          <ul className="pl-4">
            <li>
              <p className="paragraph-font mb-4">
                Assisted in teaching early engineering students the fundamentals
                of computer programming using Matlab and C through a flipped
                classroom style lecture.
              </p>
            </li>
            <li>
              <p className="paragraph-font mb-4">
                Organized and led weekly discussion sections of 30+ students to
                aid the development of basic programming concepts.
              </p>
            </li>
            <li>
              <p className="paragraph-font mb-4">
                Created homework questions and wrote tests using Matlab Grader
                to easily test and grade student solutions.
              </p>
            </li>
          </ul>
        </div>
        <div className="mb-5 home-card">
          <div className="job-header">
            <h2 className="subheader-font mb-0 no-shadow">
              {" "}
              Runner & Bar-Back{" "}
            </h2>
            <p className="paragraph-font job-date mt-2 mb-0">
              May 2018 - Aug. 2018
            </p>
          </div>
          <h4 className="paragraph-font mb-5">The Creek Restaurant and Bar</h4>
          <ul className="pl-4">
            <li>
              <p className="paragraph-font mb-4">
                Effectively communicated with customers to guarantee highest
                levels of satisfaction.
              </p>
            </li>
            <li>
              <p className="paragraph-font mb-4">
                Worked efficiently with others to ensure food and drink orders
                arrived in a timely manner.
              </p>
            </li>
          </ul>
        </div>
      </Col>
    </Row>
  );
}
