import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import WorkExperience from "../components/WorkExperience";
import Projects from "../components/Projects";
import "../css/animations.css";
import WelcomeHeader from "../components/WelcomeHeader";
import Contact from "../components/Contact";

export default function Home() {
  return (
    <div>
      <WelcomeHeader />
      <Projects />
      <WorkExperience />
      <Contact />
    </div>
  );
}
